<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-add'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_resources',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("resources", "add-resource", "add-resource") }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit" v-if="$route.name == 'r_add-resource'">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      ref="addResourceForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("resources", "add-resource", "name") }}
              </div>
              <p class="text-label">{{ displayResourceName }}</p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-resource-translate-name',
                  params: $route.params
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasTranslations"
          />
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{
              name: 'r_add-resource-select-level',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceLevel && selectedResourceLevel.name"
            >
              <div class="clebex-section-input-label">
                {{ displayLabelName("resources", "add-resource", "level-id") }}
              </div>
              <p class="text-label">{{ selectedResourceLevel.name }}</p>
            </div>
            <div class="label" v-else>
              {{ displayLabelName("resources", "add-resource", "level-id") }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field name="level_id" as="input" type="hidden" v-model="levelId" />
          </router-link>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.resource_type_id }"
        >
          <div class="error-message" v-if="errors.resource_type_id">
            {{ errors.resource_type_id }}
          </div>
          <router-link
            :to="{
              name: 'r_add-resource-select-resource-type',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceType && selectedResourceType.name"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "resources",
                    "add-resource",
                    "resource-type-id"
                  )
                }}
              </div>
              <div class="text-label">{{ selectedResourceType.name }}</div>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "add-resource",
                  "resource-type-id"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="resource_type_id"
              rules="required"
              as="input"
              type="hidden"
              v-model="resourceTypeId"
            />
          </router-link>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.resource_capacity_type_id }"
        >
          <div class="error-message" v-if="errors.resource_capacity_type_id">
            {{ errors.resource_capacity_type_id }}
          </div>
          <router-link
            :to="{
              name: 'r_add-resource-select-resource-capacity',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" v-if="capacity">
              <div class="clebex-section-input-label">
                {{ displayLabelName("resources", "add-resource", "capacity") }}
              </div>
              <div class="text-label">{{ capacity }}</div>
            </div>
            <div class="label" v-else>
              {{ displayLabelName("resources", "add-resource", "capacity") }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="capacity"
              as="input"
              type="hidden"
              v-model="capacity"
            />
            <Field
              name="max_capacity"
              as="input"
              type="hidden"
              v-model="maxCapacity"
            />
            <!--            <Field-->
            <!--              name="resource_capacity_type_id"-->
            <!--              as="input"-->
            <!--              rules="required"-->
            <!--              type="hidden"-->
            <!--              v-model="resourceCapacityTypeId"-->
            <!--            />-->
          </router-link>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.resource_model_id }"
        >
          <div class="error-message" v-if="errors.resource_model_id">
            {{ errors.resource_model_id }}
          </div>
          <router-link
            :to="{
              name: 'r_add-resource-select-resource-model',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceModel && selectedResourceModel.name"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "resources",
                    "add-resource",
                    "resource-model-id"
                  )
                }}
              </div>
              <div class="text-label">{{ selectedResourceModel.name }}</div>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "add-resource",
                  "resource-model-id"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="resource_model_id"
              as="input"
              rules="required"
              type="hidden"
              v-model="resourceModelId"
            />
          </router-link>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="addResourceRadioPropagateCapacity"
                    type="checkbox"
                    v-model="propagateCapacity"
                  />
                  <label for="addResourceRadioPropagateCapacity"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "resources",
                    "add-resource",
                    "propagate-capacity"
                  )
                }}
              </dd>
            </dl>
            <Field
              name="propagate_capacity"
              as="input"
              type="hidden"
              v-model="propagateFilter"
            />
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import httpServiceAuth, { getLang } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "AddResource",
  mixins: [helpOnlineMixin],
  data() {
    return {
      formKey: 0,
      helpSlug: "resources-add"
    };
  },
  created() {
    this.resetData();
    this.setSelectedResourceLevel(null);
  },
  computed: {
    ...mapState("resource", [
      "selectedResourceLevel",
      "selectedResourceType",
      "selectedResourceModel",
      "selectedResourceCapacityType",
      "resourceTranslations"
    ]),
    ...mapState("language", ["languages"]),
    capacity: {
      get() {
        return this.$store.state.resource.capacity;
      },
      set(val) {
        this.$store.commit("resource/setCapacity", val, { root: true });
      }
    },
    maxCapacity: {
      get() {
        return this.$store.state.resource.maxCapacity;
      },
      set(val) {
        this.$store.commit("resource/setMaxCapacity", val, { root: true });
      }
    },
    propagateCapacity: {
      get() {
        return this.$store.state.resource.propagateCapacity;
      },
      set(val) {
        this.$store.commit("resource/setPropagateCapacity", val, {
          root: true
        });
      }
    },
    levelId() {
      if (this.selectedResourceLevel) {
        return this.selectedResourceLevel.id;
      }
      return null;
    },
    resourceTypeId() {
      if (this.selectedResourceType) {
        return this.selectedResourceType.id;
      }
      return null;
    },
    resourceModelId() {
      if (this.selectedResourceModel) {
        return this.selectedResourceModel.id;
      }
      return null;
    },
    resourceCapacityTypeId() {
      if (this.selectedResourceCapacityType) {
        return this.selectedResourceCapacityType.id;
      }
      return null;
    },
    propagateFilter() {
      return Number(this.$store.state.resource.propagateCapacity);
    },
    translationFields() {
      const { languages, resourceTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.map((item, idx) => {
          if (resourceTranslations && resourceTranslations.length) {
            return {
              ...resourceTranslations[idx],
              attributes: {
                ...resourceTranslations[idx].attributes
              }
            };
          } else {
            return {
              locale: item.locale,
              attributes: {
                name: ""
              }
            };
          }
        });
      }
      return null;
    },
    hasTranslations() {
      const { languages, resourceTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === resourceTranslations.length;
      }
      return false;
    },
    displayResourceName() {
      const { resourceTranslations } = this;
      const locale = getLang();
      if (resourceTranslations && resourceTranslations.length) {
        const filtered = resourceTranslations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapActions("resource", ["setSelectedResourceLevel", "getResources"]),
    submitForm(values) {
      if (values) {
        values.day_period_id = null; // TODO
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.resourceTranslations.length) {
        values.translations = this.resourceTranslations;
      }
      httpServiceAuth
        .post(apiEndpoints.company.resources, values)
        .then(response => {
          this.getResources({ model_ids: null, reset: true });
          this.setSelectedResourceLevel(null);
          this.$store.commit("resource/setResourceData", response.data, {
            root: true
          });
          this.$router.push({ name: "r_edit-resource" });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    resetData() {
      this.$store.commit("resource/setCapacity", 0, { root: true });
      this.$store.commit("resource/setMaxCapacity", 0, { root: true });
      this.$store.commit("resource/setPropagateCapacity", false, {
        root: true
      });
      this.$store.commit("level/setLevelResources", null, { root: true });
      this.$store.commit("level/setFrequentLevelResources", null, {
        root: true
      });
      this.$store.commit("resource/setSelectedResourceCapacityType", null, {
        root: true
      });
      this.$store.commit("resource/setSelectedResourceModel", null, {
        root: true
      });
      this.$store.commit("resource/setSelectedResourceType", null, {
        root: true
      });
      this.$store.commit("resource/setResourceTranslations", [], {
        root: true
      });
    }
  },
  beforeUnmount() {
    this.setSelectedResourceLevel(null);
    this.resetData();
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
